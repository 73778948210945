import React from "react"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import Navbar from "../components/LinkedInPowertools/NavbarLIP"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

const TermsOfService = () => {
  return (
    <AuthWrapper>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle="Terms of Service"
          homePageText="Home"
          homePageUrl="/"
          activePageText="Terms of Service"
        />
        <section className="terms-of-service-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="terms-of-service-content">
                  <blockquote className="blockquote">
                    <p>
                      We collect certain data from you directly, like
                      information you enter yourself and data from third-party
                      platforms you connect with LinkedIn Powertools. We also
                      collect some data automatically, like information about
                      your usage as part of our Service.
                    </p>
                  </blockquote>
                  <h3>2. Data You Provide to Us</h3>
                  <p>
                    We may collect different data from or about you depending on
                    how you use the Services. Below are some examples to help
                    you better understand the data we collect.
                  </p>
                  <ul>
                    <li>The posts that you are commenting on</li>
                    <li>What time of day you are posting</li>
                    <li>The times and dates that you are posting</li>
                  </ul>
                  <h3>3. How We Get Data About You</h3>
                  <p>
                    We use tools like cookies, analytics services, and browser
                    storage to gather the data listed above. Some of these tools
                    offer you the ability to opt out of data collection.
                  </p>
                  <h3>4. What We Use Your Data For</h3>
                  <ol>
                    <li>Responding to your questions and concerns.</li>
                    <li>
                      Sending you administrative messages and information about
                      any changes to services or conditions.
                    </li>
                    <li>Optimising the AI to generate better comments.</li>
                  </ol>
                  <h3>5. Your Choices About the Use of Your Data</h3>
                  <p>
                    You can choose not to provide certain data to us, but you
                    may not be able to use certain features of the Services.
                  </p>
                  <ul>
                    <li>
                      To stop receiving promotional communications from us, you
                      can opt out by using the unsubscribe mechanism in the
                      promotional communication you receive or by changing the
                      email preferences in your account. Note that regardless of
                      your email preference settings, we will send you
                      transactional and relationship messages regarding the
                      Services, including administrative confirmations, order
                      confirmations, important updates about the Services, and
                      notices about our policies.
                    </li>
                    <li>
                      The browser or device you use may allow you to control
                      cookies and other types of local data storage. Your
                      wireless device may also allow you to control whether
                      location or other data is collected and shared.
                    </li>

                    <li>
                      To update data you provide directly, log into your account
                      and update your account at any time.
                    </li>
                  </ul>
                  <h3>6. Our Policy Concerning Children</h3>
                  <p>
                    We recognize the privacy interests of children and encourage
                    parents and guardians to take an active role in their
                    children’s online activities and interests. Children under
                    13 (or under 16 in the European Economic Area) should not
                    use the Services. If we learn that we’ve collected personal
                    data from a child under those ages, we will take reasonable
                    steps to delete it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default TermsOfService
